import React from "react"
import WebriqForm from "@webriq/gatsby-webriq-form"

const BottleOrderForm = () => (
	<div className="orders-form-wrapper">
		<WebriqForm
			data-form-id="8449253a-68ba-4ffb-b29f-700a408532d3"
			data-thankyou-url="/thank-you"
			webriq="true"
		>
			<div className="form-row">
				<div className="col-md-6 pr-md-3">
					<div className="form-group">
						<input
							id="order-name"
							name="Name"
							type="text"
							className="form-control"
							placeholder="Name*"
							required
						/>
						<label htmlFor="order-name">
							Name<span className="text-danger">*</span>
						</label>
					</div>
					<div className="form-group">
						<input
							id="order-email"
							name="Email"
							type="email"
							className="form-control"
							placeholder="Email*"
							required
						/>
						<label htmlFor="order-email">
							Email<span className="text-danger">*</span>
						</label>
					</div>
					<div className="form-group">
						<input
							id="order-analysis"
							name="Analysis Required (yes/no)"
							type="text"
							className="form-control"
							placeholder="Analysis Required (yes/no)*"
							required
						/>
						<label htmlFor="order-analysis">
							Analysis Required (yes/no)
							<span className="text-danger">*</span>
						</label>
					</div>
					<div className="form-group">
						<select
							className="form-control"
							id="order-sample-type"
							name="Sample Type"
							required
						>
							<option hidden>Please Choose A Sample Type</option>
							<option value="Groundwater">Groundwater</option>
							<option value="Soil">Soil</option>
							<option value="Air">Air</option>
						</select>
						<label htmlFor="order-sample-type" className="d-none">
							Please Choose A Sample Type
							<span className="text-danger">*</span>
						</label>
					</div>
					<div className="form-group date-input">
						<label htmlFor="order-date-needed">
							Date Sample Kit Is Needed
							<span className="text-danger">*</span>
						</label>
						<input
							id="order-date-needed"
							name="Date Sample Kit Is Needed"
							className="form-control"
							type="date"
							placeholder="mm/dd/yyyy"
							required
						/>
					</div>
					<div className="form-group">
						<select
							id="pre-printed-option"
							name="Pre-Printed Option"
							className="form-control"
							required
						>
							<option value hidden>
								Choose Pre-Printed Option
							</option>
							<option value="Labels">Labels</option>
							<option value="C.O.C.">C.O.C.</option>
							<option value="Both">Both</option>
						</select>
						<label htmlFor="pre-printed-option" className="d-none">
							Choose Pre-Printed Option
							<span className="text-danger">*</span>
						</label>
					</div>
				</div>
				<div className="col-md-6 pl-md-3">
					<div className="form-group">
						<input
							id="order-quantity"
							name="Quantity of Samples"
							type="text"
							className="form-control"
							placeholder="Quantity of Samples*"
							required
						/>
						<label htmlFor="order-quantity">
							Quantity of Samples<span className="text-danger">*</span>
						</label>
					</div>
					<div className="form-group">
						<select
							id="order-desired-turnaround"
							name="Desired Turnaround Time"
							className="form-control"
							required
						>
							<option value hidden>
								Choose Desired Turnaround Time
							</option>
							<option value="Same Day">Same Day</option>
							<option value="24 Hours">24 Hours</option>
							<option value="48 Hours">48 Hours</option>
							<option value="72 Hours">72 Hours</option>
							<option value="Standard">Standard</option>
						</select>
						<label htmlFor="order-desired-turnaround" className="d-none">
							Choose Desired Turnaround Time
							<span className="text-danger">*</span>
						</label>
					</div>
					<div className="form-group date-input">
						<label htmlFor="order-date-collected">
							Date Sample Being Collected
							<span className="text-danger">*</span>
						</label>
						<input
							id="order-date-collected"
							name="Date Sample Being Collected"
							className="form-control"
							type="date"
							placeholder="mm/dd/yyyy"
							required
						/>
					</div>
					<div className="form-group">
						<textarea
							id="order-message"
							name="Message"
							className="form-control"
							rows="6"
							placeholder="Message"
							required
						></textarea>
						<label htmlFor="order-message">
							Message<span className="text-danger">*</span>
						</label>
					</div>
				</div>
			</div>
			<div className="form-group">
				<div className="webriq-recaptcha" />
			</div>
			<button className="btn btn-primary" type="submit">
				Submit Bottle Order
			</button>
		</WebriqForm>
	</div>
)

export default BottleOrderForm
