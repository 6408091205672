import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import SeoComponent from "@components/seo"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import { StaticImage } from "gatsby-plugin-image"
import Swiper from "react-id-swiper"
import "react-id-swiper/lib/styles/scss/swiper.scss"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"

//forms
import ContactForm from "@components/forms/contactUs"
import BottleOrderForm from "@components/forms/bottleOrder"

//hero swiper backgrounds
import Slide1BG from "@images/slider-bg1.jpg"
import Slide2BG from "@images/slider-bg2.jpg"
import Slide3BG from "@images/slider-bg3.jpg"

//hero icons
import Icon1 from "@images/icons/terpenes.svg"
import Icon2 from "@images/icons/pesticide.svg"
import Icon3 from "@images/icons/microbials.svg"
import Icon4 from "@images/icons/heavy-metals.svg"
import Icon5 from "@images/icons/residual-solvents.svg"
import Icon6 from "@images/icons/potency.svg"
import Icon7 from "@images/icons/mycotoxins.svg"
import Icon8 from "@images/icons/water.svg"

const IndexPage = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title
  const siteDescription = data.site.siteMetadata.description

  const heroSlides = [
    {
      title: "Stop Waiting For Your Data",
      tagline: "Find out why you should be using Origins Laboratory Inc",

      bg: Slide1BG,
    },
    {
      title: "Not All Labs Are The Same",
      tagline:
        "Environmental testing of water, soil, and air contamination for the oil & gas industry since 2006.",

      bg: Slide2BG,
    },
    {
      title: "NELAP Accredited Laboratory",
      tagline:
        "With a PH.D Analytical Chemist and Forensic Geochemist on staff",

      bg: Slide3BG,
    },
  ]

  const heroSwiper = {
    loop: true,
    speed: 1500,
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
    navigation: {
      nextEl: "#hero-next",
      prevEl: "#hero-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  }

  const services = [
    {
      class: "box1",
      icon: Icon3,
      name: "Lorem ipsum dolor",
      desc: "Lorem ipsum dolor",
      desc2: "",
      link: "/",
    },
    {
      class: "box2",
      icon: Icon3,
      name: "Lorem ipsum dolor",
      desc: "Lorem ipsum dolor",
      desc2: "",
      link: "/",
    },
    {
      class: "box3",
      icon: Icon3,
      name: "Lorem ipsum dolor",
      desc: "Lorem ipsum dolor",
      desc2: "",
      link: "/",
    },
    {
      class: "box4",
      icon: Icon3,
      name: "Lorem ipsum dolor",
      desc: "Lorem ipsum dolor",
      desc2: "",
      link: "/",
    },
    {
      class: "box5",
      icon: Icon3,
      name: "Lorem ipsum dolor",
      desc: "Lorem ipsum dolor",
      desc2: "",
      link: "/",
    },
    {
      class: "box6",
      icon: Icon3,
      name: "Lorem ipsum dolor",
      desc: "Lorem ipsum dolor",
      desc2: "",
      link: "/",
    },
    {
      class: "box7",
      icon: Icon3,
      name: "Lorem ipsum dolor",
      desc: "Lorem ipsum dolor",
      desc2: "",
      link: "/",
    },
    {
      class: "box8",
      icon: Icon3,
      name: "Lorem ipsum dolor",
      desc: "Lorem ipsum dolor",
      desc2: "",
      link: "/",
    },
  ]

  return (
    <Layout>
      <SeoComponent title={siteTitle} description={siteDescription} />
      <div className="hero-swiper-wrapper">
        <div className="hero-swiper-nav">
          <button
            id="hero-prev"
            className="swiper-button-prev"
            aria-label="prev"
          />
          <button
            id="hero-next"
            className="swiper-button-next"
            aria-label="next"
          />
        </div>

        <Swiper {...heroSwiper}>
          {heroSlides.map((slide) => (
            <div
              className="hero-slide"
              style={{
                backgroundImage: `linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.8)),url(${slide.bg} )`,
              }}
            >
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-9">
                    <h2 className="display-2">{slide.title}</h2>
                    <p className="lead text-white">{slide.tagline}</p>
                    <a className="btn btn-primary mt-2" href="/#contact-us">
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Swiper>
      </div>
      <div className="below-hero">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-4 mb-4 mb-md-0">
              <div className="tile bg-offwhite-gradient text-center rounded shadow p-5">
                <StaticImage
                  src="../images/high-quality-icon.svg"
                  width={100}
                  quality={100}
                  placeholder="tracedSVG"
                />

                <h3 className="h5 text-uppercase mt-4 font-weight-bold">
                  Analyses Offered
                </h3>
              </div>
            </div>
            <div className="col-md-4 mb-4 mb-md-0">
              <div className="tile bg-offwhite-gradient text-center rounded shadow py-5 px-4">
                <StaticImage
                  src="../images/quick-time-icon.svg"
                  width={100}
                  quality={100}
                  placeholder="tracedSVG"
                />
                <h3 className="h5 text-uppercase mt-4 font-weight-bold">
                  Quick Turnaround Times
                </h3>
              </div>
            </div>
            <div className="col-md-4 mb-4 mb-md-0">
              <div className="tile bg-offwhite-gradient text-center rounded shadow py-5 px-4">
                <StaticImage
                  src="../images/customer-service-icon.svg"
                  width={100}
                  quality={100}
                  placeholder="tracedSVG"
                />
                <h3 className="h5 text-uppercase mt-4 font-weight-bold">
                  Customer Service
                </h3>
              </div>
            </div>
          </div>
          <div
            id="about"
            className="section-heading text-center text-white pt-10 pb-5"
          >
            <span>About Us</span>
            <h2>Welcome To Origins Laboratory Inc</h2>
          </div>
          <div className="bg-offwhite-gradient shadow rounded p-5">
            <div className="row">
              <div className="col-lg-8 text-center pr-lg-5 mb-5 mb-lg-0">
                <StaticImage
                  src="../images/welcome-img1.jpg"
                  placeholder="blurred"
                  quality={100}
                  layout="fullWidth"
                />
              </div>
              <div className="col-lg-4">
                <p>
                  Origins Laboratory Inc is a NELAP certified environmental
                  analytical testing laboratory serving the Exploration,
                  Production, Refining, Retail and Transportation sectors of the
                  Oil and Gas Industry. We specialize in hydrocarbon and organic
                  chemical analysis.
                </p>
                <p>
                  Our NELAP accredited laboratory has a PH.D Analytical Chemist
                  and Forensic Geochemist on staff; Origins laboratory has been
                  in the environmental testing of water, soil, and air
                  contamination for the Oil and Gas Industry since 2006.
                </p>
                <p>
                  Origins Laboratory Inc prides itself on providing accurate
                  reliable data with reproducible, traceable results and legally
                  defensible data.
                </p>
              </div>
            </div>
          </div>
          <div className="py-10">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-3 text-center mb-4 mb-md-0">
                <StaticImage
                  src="../images/nelap-logo.png"
                  placeholder="tracedSVG"
                  quality={100}
                  width={200}
                />
              </div>
              <div className="col-auto text-white">
                <h2 className="text-center text-md-left">
                  Origins Clients Receive:
                </h2>
                <ul className="styled-list-check py-4">
                  <li>NELAP Certified Data</li>
                  <li>RTD SBE Certified</li>
                  <li>Same Day and Quick Turn Analysis</li>
                  <li>Easy Electronic Data Retrieval and Delivery</li>
                  <li>Outstanding Customer Service</li>
                </ul>
                <div className="text-center text-md-left">
                  <a className="btn btn-primary" href="#about">
                    About Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="svc-section">
        <div id="services" />
        <Container>
          <h2
            className="my-0 sub-heading text-center"
            style={{ paddingTop: "100px" }}
          >
            Analytical Services
          </h2>
          <h2 className="mt-0 mb-5 text-center">
            We are CDPHE Certified and ISO Accredited
          </h2>
          <Row>
            {services.map((svc) => (
              <Col lg="3" className="my-3">
                <div
                  className={`svc-box text-center ${svc.class} d-flex justify-content-center align-items-center`}
                >
                  <Link className="text-dark" to={svc?.link}>
                    <img src={svc.icon} alt={svc.name} />
                    <h5 className="mt-3 mb-2">{svc.name}</h5>
                    <p className="mb-0">{svc.desc}</p>
                    <p style={{ fontSize: "13px" }}>{svc.desc2}</p>
                  </Link>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <div id="downloads" className="py-10 download-section">
        <div className="container">
          <div className="section-heading text-center pb-5">
            <span>Download</span>
            <h2>Origins Laboratory Inc Chain Of Custody</h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-2 text-center mb-4 mb-md-0">
              <StaticImage
                src="../images/download-pdf-icon.svg"
                placeholder="tracedSVG"
                quality={100}
              />
            </div>
            <div className="col-md-5 pl-md-5 text-center text-md-left">
              <p>
                Below is a list of files that can be downloaded through our
                website. This list will be updated from time to time, so don't
                forget to check back occasionally.
              </p>
              <a
                className="btn btn-primary"
                href="/files/originscoc.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Download
              </a>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="py-10 testimonials-section">
        <div className="container">
          <div className="section-heading text-center text-white bar-white pb-5">
            <span>Testimonials</span>
            <h2>Customer Reviews</h2>
          </div>
          <LazyLoadComponent>
            <div className="reviews-swiper-wrapper">
              <div className="reviews-swiper-nav">
                <button
                  id="reviews-prev"
                  className="swiper-button-prev"
                  aria-label="prev"
                />
                <button
                  id="reviews-next"
                  className="swiper-button-next"
                  aria-label="next"
                />
              </div>
              <div className="row justify-content-center">
                <div className="col-10">
                  <Swiper {...reviewSwiper}>
                    {reviews.map((review) => (
                      <div className="bg-white rounded shadow h-100 p-4">
                        <div className="mb-4">
                          <StaticImage
                            src="../images/double-quote.svg"
                            quality={100}
                            placeholder="tracedSVG"
                          />
                        </div>
                        <p className="mb-4">{review.body}</p>
                        <span className="d-block font-weight-bold">
                          –{review.name}
                        </span>
                      </div>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </LazyLoadComponent>

          <div className="text-center mt-5">
            <Link className="btn btn-primary btn-white" to="/">
              View All Testimonials
            </Link>
          </div>
        </div>
      </div>*/}
      <div id="bottle-orders" className="py-10 bottle-orders-section">
        <div className="container">
          <div className="section-heading text-center text-white pb-5">
            <span>Origins Bottle Orders</span>
            <h2>Origins Bottle Orders</h2>
          </div>
          <span className="d-block text-uppercase text-white pl-md-5 pb-3 text-center text-md-left">
            Place your bottle orders in the form below.
          </span>
          <div className="form-wrapper p-5 shadow rounded">
            <LazyLoadComponent>
              <BottleOrderForm />
            </LazyLoadComponent>
          </div>
        </div>
      </div>
      <div id="contact-us" className="py-10 contact-section">
        <div className="container">
          <div className="section-heading text-center text-white pb-5">
            <span>Contact Us</span>
            <h2>Get In Touch With Us For More Information</h2>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-md-7 col-lg-6 col-xl-5 mb-5 mb-md-0">
              <div className="form-wrapper bg-white p-5 rounded shadow">
                <h3 className="text-uppercase text-muted font-weight-bold">
                  Send Us a Message
                </h3>
                <p className="text-muted">
                  Please send us a message and we&#39;ll get back to you as soon
                  as possible.
                </p>
                <LazyLoadComponent>
                  <ContactForm />
                </LazyLoadComponent>
              </div>
            </div>
            <div className="col-md-4 text-white pl-md-4">
              <div className="py-4">
                <h4 className="h6 font-weight-bold text-uppercase">
                  Our Location:
                </h4>
                <a
                  href="https://goo.gl/maps/S23ZpbxwFZ6m7cMR6"
                  target="_blank"
                  rel="noreferrer"
                >
                  1725 West Elk Place
                  <br />
                  Denver, CO 80211
                  <br />
                  United States
                </a>
              </div>
              <div className="py-4 border-top border-bottom border-white">
                <h4 className="h6 font-weight-bold text-uppercase">Phone:</h4>
                <a href="tel:303.433.1322">303.433.1322</a>
                <h4 className="h6 font-weight-bold text-uppercase mt-3">
                  Email &amp; Website:
                </h4>
                <a href="mailto:O-Squad@oelabinc.com">
                  O-Squad@oelabinc.com
                  <br />
                </a>
                <a href="/">https://www.originslab.com</a>
              </div>
              <div className="py-4">
                <h4 className="h6 font-weight-bold text-uppercase">
                  Office Hours:
                </h4>
                <span>
                  Monday - Friday
                  <br />
                  9:00 AM - 5:30 PM
                  <br />
                  MST
                  <br />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    siteSettings: sanitySiteSettings {
      title
      description
    }
    aboutPage: sanityPage(_id: { regex: "/(drafts.|)about/" }) {
      id
      title
      _rawBody
    }
    allSanityPost(sort: { order: ASC, fields: publishedAt }, limit: 3) {
      edges {
        node {
          id
          title
          mainImage {
            asset {
              url
            }
          }
          slug {
            current
          }
          excerpt
          publishedAt(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`
