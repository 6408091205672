import React from "react"
import ContactUsForm from "@webriq/gatsby-webriq-form"

const ContactForm = () => (
	<ContactUsForm
		data-form-id="32d30423-063f-4896-91a0-64ee1893e1ba"
		data-thankyou-url="/thank-you"
		name="Contact Us"
		webriq="true"
	>
		<div className="form-row">
			<div className="col-md-6">
				<div className="form-group">
					<label htmlFor="firstname">
						First Name<span className="text-danger">*</span>
					</label>
					<input
						id="firstname"
						name="First Name"
						type="text"
						className="form-control"
						required
					/>
				</div>
			</div>
			<div className="col-md-6">
				<div className="form-group">
					<label htmlFor="lastname">
						Last Name<span className="text-danger">*</span>
					</label>
					<input
						id="lastname"
						name="Last Name"
						type="text"
						className="form-control"
						required
					/>
				</div>
			</div>
			<div className="col-md-6">
				<div className="form-group">
					<label htmlFor="email">
						Email<span className="text-danger">*</span>
					</label>
					<input
						id="email"
						name="Email"
						type="email"
						className="form-control"
						required
					/>
				</div>
			</div>
			<div className="col-md-6">
				<div className="form-group">
					<label htmlFor="phone">
						Phone<span className="text-danger">*</span>
					</label>
					<input
						id="phone"
						name="Phone"
						type="tel"
						className="form-control"
						required
					/>
				</div>
			</div>
			<div className="col-md-12">
				<div className="form-group">
					<label htmlFor="subject">
						Subject<span className="text-danger">*</span>
					</label>
					<input
						id="subject"
						name="Subject"
						type="text"
						className="form-control"
						required
					/>
				</div>
			</div>
			<div className="col-md-12">
				<div className="form-group">
					<label htmlFor="message">
						Message<span className="text-danger">*</span>
					</label>
					<textarea
						id="message"
						name="Message"
						className="form-control"
						rows="3"
						required
					></textarea>
				</div>
			</div>
			<div className="col-12">
				<div className="form-group">
					<div className="webriq-recaptcha" />
				</div>
			</div>
		</div>
		<button className="btn btn-primary" type="submit">
			Send Message
		</button>
	</ContactUsForm>
)

export default ContactForm
